.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
  overflow-x: hidden;
}

:root {
  font-family: Inter, sans-serif;
  font-feature-settings: "liga" 1, "calt" 1;
}
@supports (font-variation-settings: normal) {
  :root {
    font-family: InterVariable, sans-serif;
  }
}
