@media (min-width: 768px) {
  .card-wrapper {
    height: 440px;
    margin: 20px;
    width: 436px;
  }
}

.card-wrapper:hover {
  transform: translate(5px, -5px);
}

.canvas {
  height: 98%;
  left: 0px;
  pointer-events: none;
  position: absolute;
  top: 0px;
  width: 98%;
  z-index: 1;
}
