body {
  margin: 0;
  padding: 0;
  font-optical-sizing: auto;
  font-style: normal;
  background-color: #efefec;
  overflow-x: hidden;
  overflow-y: auto;
}

header {
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: space-between;
  padding-left: 10%;
}

a {
  text-decoration: none;
  color: #000000;
}

.horizontal-container {
  display: flex;
  flex-direction: row;
}

.all-sections {
  max-width: 900px;
  margin: 0 auto;
}

.info {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-bottom: 50px;
  margin: 30px;
  padding: 30px;
}

.row {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.about {
  width: 80vw;
  height: auto;
  margin-top: -5vh;
  margin-left: 20vw;
  padding-bottom: 50px;
  text-align: left;
  line-height: 1.5;
  left: 15%;
}

.intro {
  text-align: left;
  line-height: 1.5;
  left: 15%;
}

.graphic-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: auto;
}

.graphic-text-container {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  width: 100%;
  height: auto;
  margin-left: calc(30%);
}

.intro-container {
  display: flex;
  justify-content: center;
  padding-left: 10px;
  padding-right: 50px;
  align-items: center;
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.graphic-container img {
  max-width: min(110%, 1200px);
  object-fit: contain;
  filter: sepia(0.35) hue-rotate(-10deg) saturate(1.2);
}

.graphic-text-container img {
  padding-left: max(100, 5%);
  max-width: min(100%, 600px);
  object-fit: contain;
}

.oval-frame {
  display: inline-block;
  overflow: hidden;
  padding: 0px;
  width: 33%;
  border-radius: 30px;
  box-shadow: 0px 3px 3px rgba(39, 43, 162, 0.5),
    1px 1px 1px rgba(255, 255, 255, 0.5);
}

.oval-frame img {
  display: block;
  width: 100%;
}

.bold-text {
  font-weight: 600;
}

.intro-tags {
  margin-top: 38px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.image-about {
  margin-top: 38px;
  padding: 50px;
  width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 30px;
  background-color: rgb(250, 250, 250);
  box-shadow: 0px -3px 3px rgba(39, 43, 162, 0.5),
    1px 1px 1px rgba(255, 255, 255, 0.5);
}

.skills {
  margin-top: 38px;
  padding: 50px;
  width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 30px;
  background-color: rgb(250, 250, 250);
  box-shadow: 0px -3px 3px rgba(39, 43, 162, 0.5),
    1px 1px 1px rgba(255, 255, 255, 0.5);
}

.large-text {
  font-weight: 500;
  font-size: 88px;
  font-family: "Cube", sans-serif;
  text-shadow: -1px -1px 1px rgb(25, 29, 129),
    1px 1px 1px rgba(255, 255, 255, 0.5);
}

.intro-textbox {
  font-size: 93px;
  margin-top: 35px;
  text-align: left;
  line-height: 1.18;
  font-style: bold;
  font-weight: 700;
}

.about-textbox {
  font-size: 93px;
  margin-top: 35px;
  text-align: left;
  line-height: 1;
  font-style: bold;
  display: flex;
  flex-flow: wrap;
}

.about-text {
  font-family: "Miller Display", sans-serif;
  font-weight: 300;
  font-size: 25px;
}

.normal-text {
  font-family: "Miller Banner Extra Condensed", sans-serif;
  font-weight: 600;
  text-shadow: -1px -1px 1px rgb(25, 29, 129),
    1px 1px 1px rgba(255, 255, 255, 0.5);
}

.name-text {
  font-family: "Miller Banner Extra Condensed", sans-serif;
  font-style: italic;
  font-size: 120;
  position: relative;
  transition: color 0.3s ease;
  text-shadow: -1px -1px 1px rgb(25, 29, 129),
    1px 1px 1px rgba(255, 255, 255, 0.5);
}

.name-text:hover {
  color: white;
}

.hover-effect {
  transform: translate(-5px, -5px);
}

.highlight-text {
  font-family: "Miller Display", sans-serif;
  font-style: italic;
  position: relative;
  display: inline-block;
  transition: color 0.3s ease;
  text-shadow: -1px -1px 1px rgb(25, 29, 129),
    1px 1px 1px rgba(255, 255, 255, 0.5);
}

.highlight-text:hover {
  color: white;
}

.filters {
  display: flex;
  margin: 20px 0px;
}

.filters a {
  margin-right: 50px;
  color: 1010ff;
  text-decoration: none;
  font-family: "Miller Banner Extra Condensed", sans-serif;
  font-weight: 600;
  display: flex;
  flex-wrap: wrap;
}

.link {
  text-decoration: none;
  color: black;
}

.link:hover {
  color: rgb(95, 104, 112);
}

.link .card-content,
.link .card-content * {
  color: black;
}
.link .card-content .category-text {
  color: black;
}

.bottom-bar {
  display: flex;
  height: 30px;
  width: 100%;
  justify-content: space-between;
  padding: max (60%, 50px);
  margin-right: 100px;
}

.paragraph {
  margin-bottom: 50%;
}

.caption-text {
  color: #656565;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 20px;
  text-align: center;
}

.title-image-container {
  display: flex;
  /* margin:30px; */
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 40%;
  width: 100%;
  max-width: 1000px;
  min-width: 420px;
  overflow: hidden;
  border-radius: 20px;
}

.section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image-text-container {
  display: flex;
  margin: 20px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  height: auto;
  min-width: 200px;
  width: 100%;
  border-radius: 20px;
}

.flex-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  height: 100%;
  width: max(40%, 250px);
  margin-top: 10%;
}

.flex-image-caption-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex-image-container-v {
  display: flex;
  margin: 30px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  width: 70%;
  overflow: hidden;
  border-radius: 5px;
}

.flex-image-container-h {
  display: flex;
  margin: 30px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: auto;
  width: 100%;
  border-radius: 5px;
  padding: 20px;
}

.flex-image-container-h img {
  /* max-width: calc(100% / num);  */
  height: auto;
  padding: 7px;
  border-radius: 5px;
}

.normal-image {
  max-width: 100%;
  width: 100%;
  padding: 7px;
  height: inherit;
  border-radius: inherit;
}

.section {
  display: flex;
  flex-direction: column;
  padding-left: 8%;
  padding-right: 8%;
  display: flex;
  max-width: 900px;
  min-width: 300px;
  justify-content: flex-start;
  align-items: left;
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.section.is-visible {
  opacity: 1;
  transform: translateY(0);
}

.subheading {
  font-size: 32px;
  color: black;
  font-style: normal;
  font-weight: 500;
  align-items: left;
  text-align: left;
}

.subheading2 {
  color: black;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  align-items: left;
  text-align: left;
}

.subheading3 {
  color: black;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  align-items: left;
  text-align: left;
}

.card-content {
  padding-top: 8px;
  text-align: left;
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.description-textbox {
  text-overflow: ellipsis;
}

.description {
  font-size: 12px;
}

.categories {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
}

#floating-texts {
  display: flex;
  margin-top: 50px;
  padding-top: 60px;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  overflow: hidden;
  height: calc(5 * 30px);
}

.float {
  margin: 5px;
  border-color: #1010ff;
  box-shadow: inset 0 0 0 1px #1010ff;
  background-color: white;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 30px;
  box-shadow: -1px -1px 1px rgba(39, 43, 162, 0.5),
    1px 1px 1px rgba(255, 255, 255, 0.5);
}

.float span {
  font-size: 20px;
  font-family: "Miller Display", sans-serif;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 2px;
  margin-top: -2px;
  margin-bottom: -2px;
  text-shadow: -0.5px -0.5px 0.5px rgba(39, 43, 162, 0.5),
    1px 1px 1px rgba(255, 255, 255, 0.5);
}

.category span {
  font-size: 12px;
  font-style: bold;
  font-weight: 700;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: -4px;
  margin-bottom: -4px;
}

.column {
  width: calc(33.33% - 20px);
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.vertical-container {
  margin: 20px;
  display: flex;
  flex-direction: column;
}

.social-icons {
  margin-top: 120px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 200px;
}

.social-icons img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.subtext {
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #514f59;
}

.canvas-container {
  background-color: #efefec;
}

@media (max-width: 768px) {
  #selected-work {
    font-size: 20px;
  }
  .about {
    width: 90%;
  }
  .image-text-container {
    flex-direction: column;
    margin: 0px;
  }
  .image-container {
    margin-left: 0px;
  }
  .paragraph {
    margin-bottom: 5%;
  }
  .flex-text-container {
    width: 100%;
  }
  .flex-image-container-v {
    margin: 0px;
    width: 90%;
  }
  .flex-image-container-h {
    margin: 0px;
    width: 90%;
  }
  .flex-image-container-h img {
    padding: 3px;
  }
  .italics-bold {
    font-size: 80%;
  }

  .normal {
    font-size: 90%;
  }

  .section {
    max-width: 360px;
  }

  .contact-items a {
    font-size: 80%;
  }
  .contact {
    font-size: 80%;
  }
  .bottom-bar {
    margin-right: 30px;
  }
}
