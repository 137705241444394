.bottom-bar {
  position: sticky;
  width: 100%;
  z-index: 1000;
}

.contact-items {
  display: flex;
  justify-content: flex-end;
}
